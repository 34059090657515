const current = new Date();
const datenew = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`;
const invoice = {
    "id": "5df3180a09ea16dc4b95f910",
    "invoice_no": JSON.parse(localStorage.getItem('receiptdoc')),
    "tendered": JSON.parse(localStorage.getItem('newtendered')),
    "balance": "$2,283.74",
    "company": "FAIRPRICE WHOLESALERS2",
    "email": "info@fairprice.co.ke",
    "phone": "Tel: 07222222222/07333333333",
    "address": "JUJA TOWN ",
    "ClientName":"CUSTOMER  " + JSON.parse(localStorage.getItem('customername')), 
    "trans_date": `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`,
    "due_date": "2022-10-27",
    "PinNo":"PINNO: P052091638T",
    "items": [
      {
        "sno": 1,
        "desc": "ad sunt culpa occaecat qui",
        "qty": 10,
        "rate": 405.89
      },
      {
        "sno": 2,
        "desc": "cillum quis sunt qui aute",
        "qty": 5,
        "rate": 373.11
      },
      {
        "sno": 3,
        "desc": "ea commodo labore culpa irure",
        "qty": 5,
        "rate": 458.61
      },
      {
        "sno": 4,
        "desc": "nisi consequat et adipisicing dolor",
        "qty": 10,
        "rate": 725.24
      },
      {
        "sno": 5,
        "desc": "proident cillum anim elit esse",
        "qty": 4,
        "rate": 141.02
      }
    ]
  }
  
  
  export default invoice