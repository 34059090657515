import React from 'react';
import { Page, Document, Image, StyleSheet } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'
import logo from '../../../src/wine.png'
import QRCode from "react-qr-code";


const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 8,
        paddingTop: 10,
        paddingLeft:10,
        paddingRight:10,
        lineHeight: 1.0,
        flexDirection: 'column',
    }, 
    logo: {
        width: 80,
        height: 50,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
  });
  
  const Invoice = ({invoice}) => (
            <Document>
                {/* <Page size="A4" style={styles.page}> */}
                {/* size: 80mm 80mm */}
                 <Page size="A7" style={styles.page}> 
                     {/* <Image style={styles.logo} src={logo} />  */}
                    <InvoiceTitle title='SILVERLAKE WINES'/> 
                    
                    {/* <InvoiceTitle loc='MBIKA AGENCIES'/> */}
                    <BillTo invoice={invoice}/>
                    <InvoiceNo invoice={invoice}/>
                      
                    <InvoiceItemsTable invoice={invoice} />
                    <InvoiceThankYouMsg />

                      
                </Page>
                window.print(); 
            </Document>
            
        );
  
  export default Invoice