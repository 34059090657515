import React from 'react'
import {useNavigate} from "react-router-dom";
import {Form,Button,Row,Col,Table, Container } from 'react-bootstrap'
import './Madre.css';
//import NavBar from "../NavBar";

function Home({authorized}) {
  //<NavBar/>
  const navigate = useNavigate();
  if(authorized){
    // return <Redirect to ="/CapUser"/>;
    //navigate("/CapUser");
        // window.location.reload();
        //<NavBar/>
       // navigate("/NavBar");
  }
  return (
    <Container fluid>
    <Form style={{ textAlign: 'right', fontSize: '24px', fontFamily: 'Arail', width: '100%', background: '#6c757d' }}>
      <div>DashBoard</div>

    </Form>
    </Container>
    
  )
}

export default Home
