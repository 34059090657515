//import logo from './logo.svg';
//import './App.css';
import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';  
import {Nav, Navbar, Container, NavDropdown} from 'react-bootstrap';  
import { BrowserRouter ,  Routes, Route } from "react-router-dom";
import Softcraftnav from "./components/Softcraftnav";
import Home from "./components/Pages/Home";
//import Goldman from "./components/Pages/Goldman";
import Goldman from "./components/Pages/Cashsale";
import GoldmanMain from './components/Pages/GoldmanMain';
import Login from "./components/Pages/Login";
import Catalogue from "./components/Pages/Catalogue";
import ContactForm from "./components/Pages/ContactForm.jsx";
import Orders from "./components/Pages/Orders";
import Grn from "./components/Pages/Grn";
import Stockvalue from "./components/Pages/Stockvalue";
import SalesRecon from "./components/Pages/SalesRecon";
import Transfers from "./components/Pages/Transfers";
import B12 from "./components/Pages/B12";
import Vipingo from "./components/Pages/Vipingo";
import Reprint from './components/Pages/Reprint';
import Recall from './components/Pages/Recall';
import GoodsRec from './components/Pages/GoodsRec';
import FirstKings from './components/Pages/FirstKings';
import GrnRep from './components/Pages/GrnRep';
import Mbika from './Mbika';
import Protected from "./Protected";
 
function App() {
     const [isLoggedIn, setisLoggedIn] = useState(true);
  const [user, setUser] = useState(""); 
  
  return (
    // <Navbar bg="primary" expand="md">  
    <>
    
    <Container>  
      <Softcraftnav/>
      <div className="pages">
          < Routes>
          <Route path="/GoldmanMain" element={<GoldmanMain/>} />
          <Route path="/pos" element={<GoldmanMain/>} />
          <Route path="/" element={<Login/>} />
             <Route path="/" element={<softcraftnav/>} />
                       
             <Route path="/Home" element={<Protected isLoggedIn={isLoggedIn}>
             <Home/>
              </Protected>} />             
            {/* <Route path="/Capuser" element={<CapUser/>} /> */}
            {/* <Route path="/NavBar" element={<NavBar/>} /> */}
            {/* <Route path="/Softcraft" element={<Softcraft/>} />   */}
            <Route path="/mbika" element={<Mbika/>} />  
            <Route path="/GoldmanMain" element={<GoldmanMain/>} />
            
                <Route path="/pos" element={<GoldmanMain/>} /> 
                <Route path="/cashsale" element={<Goldman/>} />
                <Route path="/catalogue" element={<Catalogue/>} />
                <Route path="/account" element={<ContactForm/>} />
                <Route path="/murigah" element={<FirstKings/>} />
                <Route path="/receivedrep" element={<GrnRep/>} />
                {/* <Route path="/captain" element={<Captain/>} /> */}
                <Route path="/order" element={<Orders/>} />               
                <Route path="/received" element={<GoodsRec/>} />
                <Route path="/transfer" element={<Transfers/>} />
                <Route path="/stockvalue" element={<Stockvalue/>} />
                <Route path="/salesrecon" element={<SalesRecon/>} />
                <Route path="/enquiry" element={<B12/>} />
                {/* <Route path="/Madre" element={<Madre/>} /> */}
                {/* <Route path="/TheSage" element={<TheSage/>} /> */}
                {/* <Route path="/Autocomplete" element={<Autocomplete/>} /> */}
                {/* <Route path="/RockBottom" element={<RockBottom/>} /> */}
                {/* <Route path="/Supreme" element={<Supreme/>} /> */}
                {/* <Route path="/FetchRandomUser" element={<FetchRandomUser/>} /> */}
                {/* <Route path="/route" element={<Ace/>} /> */}
                {/* <Route path="/solace" element={<Solace/>} /> */}
                <Route path="/reprint" element={<Reprint/>} />
                <Route path="/recall" element={<Recall/>} /> 
                
                        
          </ Routes>
        </div>

    </Container>  
    </> 
  // </Navbar> 
  
  );
}

export default App;
