import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';  
import {Nav, Navbar, Container, NavDropdown} from 'react-bootstrap';  
function softcraftnav() {
  /* const [click, setClick] = useState(false);
  const activeStyle = { color: "#000000" };
  const handleClick = () => setClick(!click); */
  return (
    
    <>
  <Navbar collapseOnSelect fixed="top" expand ="sm"  bg="dark" variant="dark"> 
  
   {/* <Navbar variant="dark" bg="dark" expand="lg">  */}
  {/* <Navbar variant ="light" bg="primary" expand="md">  */}
  {/* <Navbar variant ="dark" bg="dark" expand="sm">  */}
  <Container fluid>
  <Navbar.Brand href='/'>SOFTCRAFT</Navbar.Brand>
     {/* <Navbar.Toggle aria-controls="navbar-dark-example" />  */}
     {/* <Navbar.Collapse id="navbar-dark-example">  */}
    {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
     <Navbar.Collapse id="responsive-navbar-nav">  
      <Nav className="me-auto">
        <NavDropdown
          id="basic-navbar-nav"
          title="POS"
          menuVariant="dark"
        >
          <NavDropdown.Item href='/pos'>CASHSALE</NavDropdown.Item>
          <NavDropdown.Item href='/pos'>CASHSALE</NavDropdown.Item>
          <NavDropdown.Item href='/pos'>CASHSALE</NavDropdown.Item>
          <NavDropdown.Item href='/invoice'>INVOICE</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.3">SALE RETURN</NavDropdown.Item>
          <NavDropdown.Item href="cashsale">INVENTORY</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown
              id="nav-dropdown-dark-example3"
              title="SALES REPORTS"
              menuVariant="dark">
          <NavDropdown.Item href="murigah">SALES</NavDropdown.Item>
          <NavDropdown.Item href="salesrecon">SALES RECONCILIATION</NavDropdown.Item>
          <NavDropdown.Item href="mbika">TABLES</NavDropdown.Item>
          </NavDropdown>
        </NavDropdown>

        <NavDropdown
          id="nav-dropdown-dark-example"
          title="CAPTAIN"
          menuVariant="dark"
        >
          <NavDropdown.Item href='/captain' activeStyle={{background:'tomato'}}>ORDER</NavDropdown.Item>
          <NavDropdown.Item href='/order'>CART</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.3">SALE RETURN</NavDropdown.Item>
          <NavDropdown.Item href="cashsale">INVENTORY</NavDropdown.Item>
          <NavDropdown.Divider />
          
        </NavDropdown>

        <NavDropdown
          id="nav-dropdown-dark-example"
          title="STOCKS"
          menuVariant="dark"
        >
          <NavDropdown.Item href='/catalogue'>CATALOGUE</NavDropdown.Item>
          <NavDropdown.Divider />
              <NavDropdown
              id="nav-dropdown-dark-example"
              title="PURCHASES"
              menuVariant="dark"            >
              <NavDropdown.Item href='/received'>RECEIVED</NavDropdown.Item>              
              <NavDropdown.Item href='/returned'>RETURNED</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href='/transfer'>TRANSFERS</NavDropdown.Item>
              <NavDropdown.Item href='/returned'>STOCKTAKE</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown.Divider />

            <NavDropdown
              id="nav-dropdown-dark-example"
              title="REPORTS"
              menuVariant="dark"            >
              <NavDropdown.Item href='/receivedrep'>GOODS RECEIVED</NavDropdown.Item>              
              <NavDropdown.Item href='/stockvalue'>STOCKS VALUE</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href='/returned'>STOCKS TRANSFERS</NavDropdown.Item>
              <NavDropdown.Item href='/returned'>STOCKTLIST</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown.Divider />

            <NavDropdown
              id="nav-dropdown-dark-example"
              title="MOVEMENT"
              menuVariant="dark"            >
              <NavDropdown.Item href='/enquiry'>TRACK MOVEMENT</NavDropdown.Item>              
              <NavDropdown.Item href='/login'>SOFTCRAFT LOG</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href='/returned'>STOCKS TRANSFERS</NavDropdown.Item>
              <NavDropdown.Item href='/returned'>STOCKTLIST</NavDropdown.Item>
            </NavDropdown>
        </NavDropdown>

        <NavDropdown
          id="nav-dropdown-dark-example"
          title="ACCOUNTS"
          menuVariant="dark"
        >
          <NavDropdown.Item href='/pos'>CUSTOMER</NavDropdown.Item>
          <NavDropdown.Item href='/account'>SUPPLIERS</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.3">SALE RETURN</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="#action/3.4">SALE REPORTS</NavDropdown.Item>
        </NavDropdown>


        <NavDropdown
          id="nav-dropdown-dark-example"
          title="VAN"
          menuVariant="dark"
        >
          <NavDropdown.Item href='/route'>SALES</NavDropdown.Item>
          <NavDropdown.Item href='/solace'>Printing</NavDropdown.Item>
          <NavDropdown.Item href='/account'>SUPPLIERS</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.3">SALE RETURN</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="#action/3.4">SALE REPORTS</NavDropdown.Item>
        </NavDropdown>

      </Nav>
     </Navbar.Collapse> 
  </Container>
</Navbar>
    
    </>
    
  );
}

export default softcraftnav;

