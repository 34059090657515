import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Table, Form, Button } from 'react-bootstrap'
import Posts from './Posts';
import Pagination from './Pagination';
import API from './Softcraftapi';

function Vipingo({SoftcraftItem}) {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(20);
  const [tausicode, setTausicode] = useState([]);
  const [SaleInfo, setSaleInfo] = useState({    
    itemname: "",    
  });
  const [cart, setCart] = useState([]);
  const [cnt, setCnt] = useState("");
  const [softamnt, setSoftamnt] = useState("");
  const [newamnt, setNewamnt] = useState(0);
  const [NewQty, setNewQty] = useState("");

  const butstyle = {
    float: 'right',
    marginRight: 10,
    marginBottom: 6,
    width: '80px',
    fontSize: '14px',
    height: 50,

  };

  const butstyle3 = {
    float: 'right',
    marginRight: 10,
    marginBottom: 6,
    width: '110px',
    fontSize: '14px',
    height: 50,

  };

  const butstyle1 = {
    color: 'red',
    backgroundColor: "DodgerBlue",
    float: 'left',
    marginRight: 10,
    marginBottom: 6,
    width: '150px',
    fontSize: '18px',
    height: 50,

  };

  function changeamnt(e) {   
    e.preventDefault();
    setSoftamnt(1);
    setNewamnt("");
   // setSaleInfo({ ...SaleInfo, price: "" })
  }

  function changeqty(e) {    
    e.preventDefault();
    setSoftamnt(0);
    setCnt("");
   // setSaleInfo({ ...SaleInfo, price: "" })
  }

  const onChange3 = (e) => {
    e.preventDefault();
    setCnt("")
    setCart([])
    setNewQty("")   
  }
  function addItemToCart(e) {
    e.preventDefault();
    const re = /^[0-9\b]+$/;
    const item = e.target.value;
    console.log(e.target.value);
    setCart(item);
    console.log({ cart });    
    if (softamnt===1) {
      //alert("amount change");
      setNewamnt(newamnt + item);
      setNewamnt(newamnt + item);
    setSaleInfo({ ...SaleInfo, price: newamnt })
    //setSaleInfo({ ...SaleInfo, price: newamnt })
    }
    else
    {     
    setCnt(cnt + item);  
    setCnt(cnt + item);    
    setSaleInfo({ ...SaleInfo, qty: cnt })
    setSaleInfo({ ...SaleInfo, qty: cnt })
    
    }
    
    
    
    //alert(re.test(cart))
    /* alert(cart.replace(/\D/g, ''))
    if (cart === '' || re.test(cart)) {
      //  this.setState({value: e.target.value})
      alert(cart);
       setSaleInfo({ ...SaleInfo, qty: e.target.value })
    } */
    // setSaleInfo({ ...SaleInfo, qty: cart })
  }
 

  
  

  /* useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      const res = await axios.get('https://jsonplaceholder.typicode.com/posts');
      setPosts(res.data);
      setLoading(false);
    };

    fetchPosts();
  }, []); */

  useEffect(() => {
    const ordersView = async () => {
      API.get("searchitems").then((res) => {
        setPosts(res.data);
        


      });

    };
    ordersView();
  }, [])

  //Posts Check
  const ItemOrdered =  (e) => {      
  API.get("vipingo/" + SoftcraftItem).then((res) => {   
        setSaleInfo(res.data);
    
  });
  ItemOrdered();
 
}; 



const SoftChange=(e)=> {   
  e.preventDefault();
  //setItem(e.target.value);
  alert(SoftcraftItem)
  //ItemOrdered();
 // setSaleInfo({ ...SaleInfo, price: "" })
}


  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
  //alert(SoftcraftItem)

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);
  return (
    <div className='container mt-5'>
    <Form>
      <Posts posts={currentPosts} loading={loading} />
      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={posts.length}
        paginate={paginate}
      />
                
                
    </Form>   

    {/* Icon    */}
    
           
          
    </div>
  )
}

export default Vipingo