import axios from 'axios';
export default axios.create({
//baseURL: "https://bray.softcraft.co.ke/"
//baseURL: "http://localhost:3000/"
//baseURL: "https://mushroom.azizi.co.ke/"
//baseURL: "https://tamasha2022.azizi.co.ke/"
//baseURL: "https://storemain.mbika.co.ke/"
//baseURL: "https://ecla.softcraft.co.ke/"
baseURL: "https://silverlakeapp.softcraft.co.ke/"
});
